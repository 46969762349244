.container {
    height: 100%;
    ul {
        margin: 0;
    }
    hr {
        margin: 0;
        height: auto;
        border-color: rgba(0, 0, 0, 0.12);
    }
}

.primaryBreadbrumb {
    color: #fff;
}

.headerTitleLink {
    color: #fff;
}

.contentWrapper {
    margin: 0 auto;
    flex: 1;
    min-height: 100%;
    width: 100%;
    background-color: #ecebeb;
}

.content {
    width: 1100px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 16px;
    margin-bottom: 16px;
}

.contentContainer {
    padding: var(--page-padding);
    height: 100%;
}

@media (max-width: 1260px) {
    .content {
        width: 1024px;
    }
}
@media (max-width: 1024px) {
    .content {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}
@media (max-width: 600px) {
    .content {
        width: 100%;
        margin-left: 0;
        margin-right: 0;
        min-width: 100%;
    }
}

.headerTitleLink {
    text-decoration: none;
}

.drawerTitle {
    line-height: 1 !important;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 24px !important;
}

@media screen and (max-width: 1024px) {
    .drawerTitle {
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 16px !important;
    }
}
.drawerTitleLogo {
    padding-right: 16px;
}
.drawerTitleText {
    display: inline-block;
    vertical-align: middle;
    font-size: 0.8em;
}

.navigation {
    padding: 8px 5px 8px 0 !important;
}

.navigationLink {
    padding: 12px 20px !important;
    border-radius: 0 50px 50px 0;
    text-decoration: none;
}
@media screen and (max-width: 1024px) {
    .navigationLink {
        padding: 12px 16px !important;
    }
}

.navigationIcon {
    margin-right: 16px;
}

.iconGitHub {
    width: 24px;
    height: 24px;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHN0eWxlPSJ3aWR0aDoyNHB4O2hlaWdodDoyNHB4IiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogICAgPHBhdGggZmlsbD0iIzc1NzU3NSIgZD0iTTEyLDJBMTAsMTAgMCAwLDAgMiwxMkMyLDE2LjQyIDQuODcsMjAuMTcgOC44NCwyMS41QzkuMzQsMjEuNTggOS41LDIxLjI3IDkuNSwyMUM5LjUsMjAuNzcgOS41LDIwLjE0IDkuNSwxOS4zMUM2LjczLDE5LjkxIDYuMTQsMTcuOTcgNi4xNCwxNy45N0M1LjY4LDE2LjgxIDUuMDMsMTYuNSA1LjAzLDE2LjVDNC4xMiwxNS44OCA1LjEsMTUuOSA1LjEsMTUuOUM2LjEsMTUuOTcgNi42MywxNi45MyA2LjYzLDE2LjkzQzcuNSwxOC40NSA4Ljk3LDE4IDkuNTQsMTcuNzZDOS42MywxNy4xMSA5Ljg5LDE2LjY3IDEwLjE3LDE2LjQyQzcuOTUsMTYuMTcgNS42MiwxNS4zMSA1LjYyLDExLjVDNS42MiwxMC4zOSA2LDkuNSA2LjY1LDguNzlDNi41NSw4LjU0IDYuMiw3LjUgNi43NSw2LjE1QzYuNzUsNi4xNSA3LjU5LDUuODggOS41LDcuMTdDMTAuMjksNi45NSAxMS4xNSw2Ljg0IDEyLDYuODRDMTIuODUsNi44NCAxMy43MSw2Ljk1IDE0LjUsNy4xN0MxNi40MSw1Ljg4IDE3LjI1LDYuMTUgMTcuMjUsNi4xNUMxNy44LDcuNSAxNy40NSw4LjU0IDE3LjM1LDguNzlDMTgsOS41IDE4LjM4LDEwLjM5IDE4LjM4LDExLjVDMTguMzgsMTUuMzIgMTYuMDQsMTYuMTYgMTMuODEsMTYuNDFDMTQuMTcsMTYuNzIgMTQuNSwxNy4zMyAxNC41LDE4LjI2QzE0LjUsMTkuNiAxNC41LDIwLjY4IDE0LjUsMjFDMTQuNSwyMS4yNyAxNC42NiwyMS41OSAxNS4xNywyMS41QzE5LjE0LDIwLjE2IDIyLDE2LjQyIDIyLDEyQTEwLDEwIDAgMCwwIDEyLDJaIiAvPgo8L3N2Zz4=);
}
