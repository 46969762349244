.card {
    width: 100%;
    padding: var(--card-padding);
    margin: var(--card-margin-y) 0;
}

.header {
    font-size: var(--h1-size);
    font-weight: 500;
    margin: 0 0 0.5rem 0;
}

.reportCardContainer {
    display: flex;
    justify-content: space-between;
}

.reportCardHealthInnerContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.reportCardHealthRating {
    font-size: 2rem;
    font-weight: bold;
    color: var(--success);
}

.reportCardList {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.reportCardList li {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
}

.reportCardList li span {
    margin: 0;
    padding: 0;
    margin-left: 0.5rem;
    font-size: var(--p-size);
}

.check,
.danger {
    margin-right: 5px;
}

.check {
    color: var(--success);
}

.danger {
    color: var(--danger);
}

.reportCardActionContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.reportCardActionText {
    max-width: 300px;
    font-size: var(--p-size);
}

.reportCardBtn {
    background-color: #f2f2f2;
}

.healthDanger {
    color: var(--danger);
}

.healthWarning {
    color: var(--warning);
}
